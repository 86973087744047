import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Rozcestnik from "../components/Rozcestnik";
import styled from "styled-components";
import Img from "gatsby-image";
import Breadscrum from "../components/Breadscrum";
import { useMediaQuery } from 'react-responsive'
import PoradnaSipka from "../assets/img/poradnaSipka.png";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";


const PoradnaJumbo = styled.section`
  position: relative;
  padding: 90px 0;
  background: #B79489;

  @media (max-width: 899px) {
    padding: 60px 0;
  }

  .jumboImg {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 0;
  }

  .jumboInner {
    position: relative;
    z-index: 1;
    width: calc(100% - 50px);
    margin: 0 auto;

    @media (max-width: 899px) {
      max-width: 600px;
    }
  }

  h1 {
    font-family: NewKansas;
    font-size: 64px;
    color: #FFFFFF;
    text-align: center;
    text-shadow: 0 2px 31px rgba(0,0,0,0.35);

    @media (max-width: 899px) {
      font-size: 36px;
      line-height: 42px;
      text-align: left;
    }
  }

  p {
    font-family: Visuelt-Regular, Helvetica;
    color: #FFFFFF;
    text-shadow: 0 2px 31px rgba(0,0,0,0.46);
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin: 0 auto;
    max-width: 780px;
    margin-top: 30px;

    @media (max-width: 899px) {
      margin-top: 25px;
      font-size: 16px;
      line-height: 25px;
      text-align: left;
    }
  }

  .poradnaSipka {
    margin-left: auto;
    margin-right: auto;
    width: 20px;
    margin-bottom: 40px;
    display: block;
    margin-top: 30px;

    @media (min-width: 900px) {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
`;

const PoradnaClanky = styled.section`

  margin: 0 auto;
  background: #FBFBFA;
  padding-bottom: 60px;
  padding-top: 40px;
  margin-bottom: -20px;

  @media (min-width: 900px) {
    padding-top: 80px;
    margin-bottom: -50px;
  }

  a:hover {
    text-decoration: underline;
  }

  .sekce {
    width: calc(100% - 50px);
    margin: 0 auto;
    margin-bottom: 60px;
    max-width: 600px;

    @media (min-width: 900px) {
      max-width: 1150px;
    }

    h2 {
      font-family: Visuelt-Medium, Helvetica;
      font-size: 24px;
      color: #0c1c31;
      line-height: 28px;
      margin-bottom: 40px;
    }

    ul {
      @media (min-width: 900px) {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-column-gap: 40px;
        grid-row-gap: 60px;
        margin: 0;
        padding: 0;
        margin-bottom: 80px;
      }
      
      @media (min-width: 1100px) {
        grid-column-gap: 60px;
      }

      li {
        list-style: none;
        border: 0;
        padding: 0;
        margin-bottom: 50px;

        @media (min-width: 1100px) {
          margin: 0;
        }

        .obrazek {
          @media (max-width: 899px) {
            max-width: 370px;
          }
        }

        a {
          font-family: Visuelt-Regular, Helvetica;
          font-size: 16px;
          color: #2A2A2A;
          line-height: 24px;
          text-decoration: none;

          &:hover {
            text-decoration: none;

            h3 {
              text-decoration: underline;
            }
          }

          h3 {
            font-family: Visuelt-Medium;
            font-size: 20px;
            line-height: 28px;
            color: #0c1c31;
            margin-top: 25px;
          }

          span {
            text-transform: uppercase;
            color: rgba(0,0,0,0.3);
            font-family: Visuelt-Bold;
            font-size: 15px;
            margin-top: 15px;
            display: block;
          }

          p {
            font-family: Visuelt-Regular;
            font-size: 16px;
            line-height: 24px;
            margin-top: 12px;
            color: rgba(0,0,0,0.7);
          }
          
          .gatsby-image-wrapper {
            width: 100%;
            border-radius: 10px;
            height: 160px;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
`;


const Poradna = () => {

  // stahujeme články

  const data = useStaticQuery(graphql`{
    zaveti: allContentfulBlog(filter: {kategorie: {nzev: {eq: "Právo a dědictví"}}}) {
      edges {
        node {
          id
          displayHeading
          datum(formatString: "DD. MM. YYYY")
          slug
          kategorie {
            nzev
          }
          perex {
            perex
          }
          obrazek {
            fluid(maxWidth: 930, maxHeight: 600) {
               ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
            }
          }
        }
      }
    }

    umrti: allContentfulBlog(filter: {kategorie: {nzev: {eq: "Úmrtí a pohřeb"}}}) {
      edges {
        node {
          id
          displayHeading
          datum(formatString: "DD. MM. YYYY")
          slug
          kategorie {
            nzev
          }
          perex {
            perex
          }
          obrazek {
            fluid(maxWidth: 930, maxHeight: 600) {
               ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
            }
          }
        }
      }
    }

    deditstvi: allContentfulBlog(filter: {kategorie: {nzev: {eq: "Další články"}}}) {
      edges {
        node {
          id
          displayHeading
          datum(formatString: "DD. MM. YYYY")
          slug
          kategorie {
            nzev
          }
          perex {
            perex
          }
          obrazek {
            fluid(maxWidth: 930, maxHeight: 600) {
               ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
            }
          }
        }
      }
    }

    img: file(relativePath: { eq: "poradnaBackground.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    imgSmall: file(relativePath: { eq: "poradnaBackgroundSmall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }`);


  const isLess900 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <Layout V2>
      <SEO
        title="Poradna"
        description="Připravili jsem pro vás desítky článků. Pomůžeme vám zorientovat se ve všem, co se smrti týče: Kolik peněz stojí pohřeb? Jak vydědit potomka? Podle čeho si vybrat pohřební službu?"
        image={"/poradnaOg.png"}
      />

      <Breadscrum
        visible={false}
        stranky={[
          {name: "Domů", url: "/"},
          {name: "Poradna", url: "/poradna/"},
        ]}
      />

      <PoradnaJumbo>
        <div className="jumboInner">
          <h1>Poradna</h1>
          <p>Připravili jsem pro vás desítky článků. Pomůžeme vám zorientovat se ve všem, co se smrti týče.</p>
        </div>
        {isLess900 ?
          <Img alt="Goodbye Poradna" className="jumboImg" fluid={data.imgSmall.childImageSharp.fluid} />
        :
          <Img alt="Goodbye Poradna" className="jumboImg" fluid={data.img.childImageSharp.fluid} />
        }
      </PoradnaJumbo>

      <PoradnaClanky>
        <div className="zaveti sekce" >
          <h2>Právo a dědictví</h2>
          <ul>
            {data.zaveti.edges.map((o) => {

              let krystofJePyca = o.node.perex.perex.slice(0, 120).split(" ");
              let krystofJePyca2 = krystofJePyca.splice(0, krystofJePyca.length - 1,).join(" ") + "...";

              return (
                <li key={o.node.id}>
                  <Link to={`/poradna/${o.node.slug}/`}>
                    <Img className="obrazek" alt={o.node.displayHeading} fluid={o.node.obrazek.fluid} />
                    <h3>{o.node.displayHeading}</h3>
                    <p>{krystofJePyca2}</p>
                    <span>{o.node.datum}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>

        <div className="umrti sekce">
          <h2>Úmrtí a pohřeb</h2>
          <ul>
            {data.umrti.edges.map((o) => {

              let krystofJePyca = o.node.perex.perex.slice(0, 120).split(" ");
              let krystofJePyca2 = krystofJePyca.splice(0, krystofJePyca.length - 1,).join(" ") + "...";

              return (
                <li key={o.node.id}>
                  <Link to={`/poradna/${o.node.slug}/`}>
                    <Img className="obrazek" alt={o.node.displayHeading} fluid={o.node.obrazek.fluid} />
                    <h3>{o.node.displayHeading}</h3>
                    <p>{krystofJePyca2}</p>
                    <span>{o.node.datum}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="deditstvi sekce">
          <h2>Další články</h2>
          <ul>
            {data.deditstvi.edges.map((o) => {

              let krystofJePyca = o.node.perex.perex.slice(0, 120).split(" ");
              let krystofJePyca2 = krystofJePyca.splice(0, krystofJePyca.length - 1,).join(" ") + "...";

              return (
                <li key={o.node.id}>
                  <Link to={`/poradna/${o.node.slug}/`}>
                    <Img className="obrazek" alt={o.node.displayHeading} fluid={o.node.obrazek.fluid} />
                    <h3>{o.node.displayHeading}</h3>
                    <p>{krystofJePyca2}</p>
                    <span>{o.node.datum}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </PoradnaClanky>


      {/* <Rozcestnik zaveti pohrby parte /> */}
      <SeVsimPomuzeme />
    </Layout>
  );
}

export default Poradna;
